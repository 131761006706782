import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

export default class ContactUsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s | Pathlab">
          <title>{`Contact Us`}</title>
          <meta
            name="description"
            content={`Get in touch with the team at Pathlab.`}
          />
        </Helmet>
        <section className="section">
          <div className="container content-border">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <iframe
                    id="contact-us-form"
                    title="Contact Us Form"
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=AWoWqGyZBU2T4SubP7FHPuMFEfVfJNdMrPLc4msvdpFUQlAwQUJJVzBaOTJMUkE0V0xGUlJIMjBDNC4u&embed=true"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
